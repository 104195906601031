<template>
  <main
      class="main"
      tabindex="0"
      @keydown="checkShortcuts">
    <Suspense>
      <router-view
          class="main__page" />

      <template #fallback>
        <article
            class="main__page main__page--loading">
        </article>
      </template>
    </Suspense>
  </main>
</template>
<script>
import {
  useRoute,
  useRouter,
} from 'vue-router';
import { useI18n } from 'vue-i18n';

export default {
  props: {
    notFound: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { locale, t } = useI18n();

    return {
      locale,
      t,
      route,

      checkShortcuts(ev) {
        if (ev.shiftKey && ev.ctrlKey && [].indexOf(ev.code) !== -1) {
          ev.preventDefault();
          ev.stopPropagation();
          router.push(shortcuts[ev.code].value);
        }
      },
    };
  },
};
</script>

<style>

.main {
  display: grid;
  grid-template-rows: auto 1fr auto;

  min-height: 100vh;

  &__page {
    min-height: 100vh;

    color: var(--color-input-text);
    background-color: var(--color-background);

    transition: background-color 100ms linear;

  }


}

</style>
