<template>
  <div
ref="root"
class="map-box"/>
</template>
<script>
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import {
  onMounted,
  onUpdated,
  ref,
  toRefs,
  watch,
} from 'vue';

export default {
  props: {
    name: {
      type: String,
      default: () => 'Map',
    },
    sources: {
      type: Object,
      default: () => ({}),
    },
    center: {
      type: Array,
      default: () => [0, 0],
    },
    layers: {
      type: Array,
      default: () => [],
    },
    zoom: {
      type: Number,
      default: () => 9,
    },
    projection: {
      type: String,
      default: () => 'mercator',
    },
  },

  emits: ['update:zoom', 'update:center'],

  setup(props, { emit }) {
    const root = ref(null);
    const {
      sources, zoom, center, projection, layers, name,
    } = toRefs(props);

    const map = ref(null);

    onUpdated(() => map.value?.triggerRepaint());

    onMounted(() => {
      map.value = new mapboxgl.Map({
        container: root.value,
        center: center.value,
        zoom: zoom.value,
        projection: projection.value,
        accessToken: 'pk.eyJ1IjoiYnBlc2NoaWVyIiwiYSI6IjkyMUNqeDgifQ.pJqJa00HCxj0zz4SInKGfQ',
        style: {
          version: 8,
          name: name.value,
          sprite: 'mapbox://sprites/mapbox/streets-v8',
          glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
          sources: sources.value,
          layers: layers.value,
        },
      });
      map.value.addControl(new mapboxgl.NavigationControl({ visualizePitch: true }));
      map.value.addControl(new mapboxgl.ScaleControl({ unit: 'nautical' }), 'bottom-right');
      map.value.on('zoomend', () => emit('update:zoom', map.value.getZoom()));
      map.value.on('dragend', () => emit('update:center', [map.value.getCenter().lng, map.value.getCenter().lat]));

      map.value.on('click', (e) => {
        console.log(e);
        const height = 50;
        const width = 50;
        const results = map.value.queryRenderedFeatures([
          [e.point.x - width / 2, e.point.y - height / 2],
          [e.point.x + width / 2, e.point.y + height / 2],
        ]);
        console.log(results);
      });

    });

    watch(layers, (newLayers, oldLayers) => {
      oldLayers?.forEach((l) => map.value.removeLayer(l.id));
      newLayers?.forEach((l) => map.value.addLayer(l));
    });

    watch(sources, (newSources, oldSources) => {
      Object.entries(oldSources)?.forEach(([key]) => map.value.removeSource(key));
      Object.entries(newSources)?.forEach(([key, s]) => map.value.addSource(key, s));
    });

    return {
      root,
    };
  },
};

</script>
