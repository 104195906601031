<template>
  <CheckBox
      name="layer"
      :value="layer.code"
      :model-value="selected">
    {{ layer.code }}
  </CheckBox>
</template>
<script>
import CheckBox from '../widgets/forms/CheckBox.vue';

export default {
  components: { CheckBox },
  props: {
    layer: {
      type: Object,
      default: () => null,
    },
    selected: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['update:selected'],
};
</script>
